import React from "react";

// External
import styled from "styled-components";
import { Flex } from "../../../styles/global-styles";
import { StaticImage } from "gatsby-plugin-image";
import {
  FiMail,
  FiGithub,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  // FiSend,
} from "react-icons/fi";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// Internal
import { name, links } from "../../../../config";
import { breakpoints, colors, fontFamily, spacing } from "../../../../theme";

// Components
import IconButton from "../../form/IconButton";

// Hooks

// data
const socialLinks = [
  {
    name: "twitter",
    icon: <FiTwitter />,
    link: links.twitter,
  },

  {
    name: "linkedin",
    icon: <FiLinkedin />,
    link: links.linkedin,
  },

  {
    name: "instagram",
    icon: <FiInstagram />,
    link: links.instagram,
  },

  {
    name: "email",
    icon: <FiMail />,
    link: links.email,
  },
  {
    name: "github",
    icon: <FiGithub />,
    link: links.github,
  },
];

const Container = styled.footer`
  background-color: ${colors.grey.dark};
  color: ${colors.white};
  padding: ${spacing(8)} ${spacing(4)};
`;
const Content = styled.div`
  display: block;
  @media only screen and (min-width: ${breakpoints.desktop}) {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin: 0 auto;
  }
`;
const Column = styled.div``;
const Avatar = styled.div``;
const Name = styled.div`
  font-size: 20px;
  font-family: ${fontFamily.bold};
  margin-left: ${spacing(4)};
  width: 13ch;
`;

const HelperText = styled.span`
  color: ${colors.grey.medium};
  font-family: ${fontFamily.regular};
  display: block;
  line-height: 140%;
  letter-spacing: 1%;

  &:first-of-type {
    margin-top: ${spacing(10)};
  }
`;
const SocialLinks = styled.div`
  margin-top: ${spacing(10)};
`;

const SocialLink = styled(IconButton)`
  margin: 0 ${spacing(3)} 0 0;
`;

const ColumnHeading = styled.h6`
  font-size: 20px;
  font-family: ${fontFamily.medium};
  margin: ${spacing(8)} 0 ${spacing(4)};
`;
const LinkList = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledAniLink = styled(AniLink)`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-family: ${fontFamily.regular};
  font-weight: normal;
  color: ${props => props.color || colors.black};

  &:hover {
    font-weight: bold;
  }
  display: block;
  color: ${colors.medium};
  margin: ${spacing(2)} 0;

  @media only screen and (min-width: ${breakpoints.desktop}) {
    margin: ${spacing(3)} ${spacing(0.5)};
  }
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <Column>
          <Flex>
            <Avatar>
              <StaticImage
                src="../../../images/me.jpeg"
                alt="Parmeet Singh"
                width={86}
                height={86}
                imgStyle={{
                  borderRadius: "50%",
                }}
                placeholder="none"
              />
            </Avatar>
            <Name>{name}</Name>
          </Flex>
          <HelperText>Copyright © 2021 {name}</HelperText>
          <HelperText>All rights reserved</HelperText>
          <SocialLinks>
            {socialLinks.map(item => (
              <SocialLink
                href={item.link}
                key={item.name}
                target="_blank"
                disableGhost
                ariaLabel={item.name}
              >
                {item.icon}
              </SocialLink>
            ))}
          </SocialLinks>
        </Column>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <StyledAniLink cover bg="#000" to="/projects" color={colors.white}>
              Projects
            </StyledAniLink>
            <StyledAniLink cover bg="#000" to="/about" color={colors.white}>
              About Me
            </StyledAniLink>
            <StyledAniLink cover bg="#000" to="/my-skills" color={colors.white}>
              Skills
            </StyledAniLink>
            <StyledAniLink cover bg="#000" to="/blog" color={colors.white}>
              Blog
            </StyledAniLink>
            <StyledAniLink cover bg="#000" to="/links" color={colors.white}>
              Links
            </StyledAniLink>
            <StyledAniLink
              cover
              bg="#000"
              to="/contact-me"
              color={colors.white}
            >
              Contact Me
            </StyledAniLink>
            {/* <StyledAniLink href="/blog" color={colors.white}>Newsletter</StyledAniLink> */}
          </LinkList>
        </Column>

        <Column>
          <ColumnHeading>Services</ColumnHeading>
          <LinkList>
            <StyledAniLink cover bg="#000" to="/my-skills" color={colors.white}>
              Web Development
            </StyledAniLink>
            <StyledAniLink cover bg="#000" to="/my-skills" color={colors.white}>
              UI/UX Designing
            </StyledAniLink>
          </LinkList>
        </Column>

        {/*  <Column>
          <ColumnHeading>Stay up to date</ColumnHeading>
          <InputContainer>
            <EmailInput placeholder="Your email address" icon="send" />
            <SubmitNewsletterEmailButton size="small">
              <FiSend />
            </SubmitNewsletterEmailButton>
          </InputContainer>
        </Column> */}
      </Content>
    </Container>
  );
};

export default Footer;
