import React from "react";

// External
import styled from "styled-components";
import { rgba } from "polished";
import PropTypes from "prop-types";

// Internal
import { colors, components } from "../../../../theme";

const IconButtonStyles = styled.button`
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  cursor: pointer;

  color: ${props => props.iconColor};

  height: ${props => components.iconButton.size[props.size]};
  width: ${props => components.iconButton.size[props.size]};

  font-size: ${props => components.iconButton.iconSize[props.size]};

  background-color: ${props =>
    props.disableGhost ? rgba(props.bgColor, 0.1) : "transparent"};
  &:hover,
  &:focus {
    background-color: ${props => rgba(props.bgColor, 0.2)};
  }

  &:active {
    background-color: ${props => rgba(props.bgColor, 0.3)};
  }
`;

const IconButton = props => {
  return (
    <IconButtonStyles as={props.href ? "a" : "button"} {...props}>
      {props.children}
    </IconButtonStyles>
  );
};

IconButton.defaultProps = {
  size: "medium",
  bgColor: colors.white,
  iconColor: colors.white,
};

IconButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  bgColor: PropTypes.string,
  iconColor: PropTypes.string,
  disableGhost: PropTypes.bool,
  children: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
};

export default IconButton;
