import React, { useState } from "react";

// External
import styled from "styled-components";
// import { FiMenu, FiX } from "react-icons/fi";
import { useLocation } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image";
import {
  FiMail,
  FiGithub,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  FiMenu,
  FiX,
} from "react-icons/fi";
import { scroller } from "react-scroll";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

// Internal
import { name, links } from "../../../../config";
import { fontFamily, colors, spacing, breakpoints } from "../../../../theme";

// Components
import { Flex } from "../../../styles/global-styles";
import Button from "../../form/Button";
import IconButton from "../../form/IconButton";
import Link from "../../Link";

// Hooks
import useMediaQuery from "../../../hooks/use-media-query";
import useScrollBlock from "../../../hooks/use-scroll-block";

// State
import { safeWindow } from "../../../utils/helpers/ssr";
import useHasMounted from "../../../hooks/use-has-mounted";

// data
const socialLinks = [
  {
    name: "twitter",
    icon: <FiTwitter size="20px" color="#666" />,
    link: links.twitter,
  },

  {
    name: "linkedin",
    icon: <FiLinkedin size="20px" color="#666" />,
    link: links.linkedin,
  },

  {
    name: "instagram",
    icon: <FiInstagram size="20px" color="#666" />,
    link: links.instagram,
  },

  {
    name: "email",
    icon: <FiMail size="20px" color="#666" />,
    link: links.email,
  },
  {
    name: "github",
    icon: <FiGithub size="20px" color="#666" />,
    link: links.github,
  },
];

const HamburgerMenuButton = styled(IconButton)``;

const SocialLink = styled(IconButton)`
  margin: ${spacing(1)} ${spacing(3)};
`;

const StyledButton = styled(Button)`
  margin: 0 12px 0 auto;
`;
const NavDrawerLink = styled.a`
  display: block;
  padding: 12px;
  text-decoration: none;
  font-size: 32px;
  font-family: ${fontFamily.extraBold};
  margin: 20px 40px;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: #ff8e4e;
  }
`;

// styles
const WebsiteName = styled.h2`
  font-size: 14px;
  font-family: ${fontFamily.bold};
  // font-family: "Plus Jakarta Sans Bold";
  letter-spacing: 0.5%;
  cursor: pointer;
  margin-left: ${spacing(1)};

  @media only screen and (min-width: 350px) {
    font-size: 16px;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${spacing(4)};
    font-size: 20px;
  }
`;

const NavigationDrawer = styled.div`
  position: fixed;
  background: white;
  width: 100%;
  height: ${safeWindow.innerHeight}px;
  z-index: 3;
  top: 60px;
`;

const HeaderContainer = styled(Flex)`
  height: 60px;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  background: ${colors.white};

  position: fixed;
  top: 0;
  z-index: 20;
  width: calc(100% - 4px);

  @media only screen and (min-width: 450px) {
    padding: 0 12px;
    width: calc(100% - 24px);
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    height: 80px;
    padding: 0 24px;
  }
`;

const LinkContainer = styled.div``;

const SocialLinksContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 60px;
`;

const SideContainer = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
`;

const AllDesktopLinkContainer = styled.div`
  margin-right: 18px;
  display: flex;
`;

const DesktopLinkContainer = styled.div`
  margin-right: 12px;
  // background: green;
  width: 100px;
  text-align: center;
`;

const ImageWrapper = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  cursor: pointer;
`;

const data = [
  {
    label: "Home",
    path: "/",
    name: "hero",
    isCTA: false,
    isDesktop: false,
    isMobile: true,
  },
  {
    label: "Projects",
    path: "/projects",
    name: "projects",
    isCTA: false,
    isDesktop: true,
    isMobile: true,
  },
  {
    label: "Testimonials",
    path: "/testimonials",
    name: "testimonials",
    isCTA: false,
    isDesktop: true,
    isMobile: true,
  },
  {
    label: "Blog",
    path: "/blog",
    name: "blog",
    isCTA: false,
    isDesktop: true,
    isMobile: true,
  },
  {
    label: "About",
    path: "/about",
    name: "aboutMe",
    isCTA: false,
    isDesktop: false,
    isMobile: true,
  },
  {
    label: "Contact Me",
    path: "/contact-me",
    name: "contactMe",
    isCTA: true,
    isDesktop: true,
    isMobile: true,
  },
];

const StyledAniLink = styled(AniLink)`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-family: ${fontFamily.regular};
  font-weight: normal;
  color: ${props => props.color || colors.black};

  &:hover {
    font-weight: bold;
  }
`;

const Header = () => {
  const { pathname } = useLocation();
  const isHomepage = pathname === "/";
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.tablet} )`);

  const [isNavigationDrawerOpen, setNavigationDrawerVisibility] = useState();

  const [blockScroll, allowScroll] = useScrollBlock();
  const handleToggle = () => {
    if (isNavigationDrawerOpen) {
      setNavigationDrawerVisibility(false);
      allowScroll();
    } else {
      setNavigationDrawerVisibility(true);
      blockScroll();
    }
  };

  const handleHomeClick = () => {
    // if current page is landing page then scroll to the required section, else redirect to individual page
    // navigate("/");
    if (isHomepage) {
      scroller.scrollTo("hero", {
        duration: 700,
        smooth: true,
        offset: -80,
      });
    } else {
      // navigate("/");
    }
  };

  const handleNavigation = navItem => {
    if (isHomepage && navItem.name !== "aboutMe") {
      scroller.scrollTo(navItem.name, {
        duration: 700,
        smooth: true,
        offset: -80,
      });
    } else {
      // navigate(navItem.path);
    }
  };

  // scroll
  // const handleHomePage

  const closeNavigationDrawer = () => {
    setNavigationDrawerVisibility(false);
    allowScroll();
  };

  const closeAndNavigate = navItem => {
    closeNavigationDrawer();
    handleNavigation(navItem);
  };

  const hasMounted = useHasMounted();

  return (
    <>
      <HeaderContainer>
        <ReactPlaceholder
          type="round"
          style={{ width: "48px", height: "48px" }}
          ready={hasMounted}
          showLoadingAnimation
        >
          {isMobile ? (
            <HamburgerMenuButton
              onClick={handleToggle}
              iconColor={colors.black}
              ariaLabel="menu"
            >
              {!isNavigationDrawerOpen && <FiMenu size="30px" />}
              {isNavigationDrawerOpen && <FiX size="30px" />}
            </HamburgerMenuButton>
          ) : isHomepage ? (
            <ImageWrapper onClick={handleHomeClick}>
              <StaticImage
                src="../../../images/me.jpeg"
                alt="Parmeet Singh"
                width={208}
                height={208}
                imgStyle={{
                  borderRadius: "50%",
                }}
                placeholder="none"
              />
            </ImageWrapper>
          ) : (
            <StyledAniLink cover bg="#000" to="/">
              <ImageWrapper>
                <StaticImage
                  src="../../../images/me.jpeg"
                  alt="Parmeet Singh"
                  width={208}
                  height={208}
                  imgStyle={{
                    borderRadius: "50%",
                  }}
                  placeholder="none"
                />
              </ImageWrapper>
            </StyledAniLink>
          )}
        </ReactPlaceholder>

        {isHomepage ? (
          <WebsiteName onClick={handleHomeClick}>{name}</WebsiteName>
        ) : (
          <StyledAniLink cover bg="#000" to="/">
            <WebsiteName onClick={handleHomeClick}>{name}</WebsiteName>
          </StyledAniLink>
        )}

        <SideContainer>
          {isMobile ? (
            data
              .filter(({ isCTA, isMobile }) => isCTA && isMobile)
              .map(navItem => {
                if (isHomepage) {
                  return (
                    <StyledButton
                      size="small"
                      onClick={() => handleNavigation(navItem)}
                      key={navItem.name}
                    >
                      {navItem.label}
                    </StyledButton>
                  );
                }

                return (
                  <StyledAniLink cover bg="#000" to={navItem.path}>
                    <StyledButton size="small" key={navItem.name}>
                      {navItem.label}
                    </StyledButton>
                  </StyledAniLink>
                );
              })
          ) : (
            <>
              <AllDesktopLinkContainer>
                {data
                  .filter(({ isCTA, isDesktop }) => !isCTA && isDesktop)
                  .map(navItem => {
                    if (isHomepage && navItem.name !== "aboutMe") {
                      return (
                        <DesktopLinkContainer key={navItem.name}>
                          <Link onClick={() => handleNavigation(navItem)}>
                            {navItem.label}
                          </Link>
                        </DesktopLinkContainer>
                      );
                    }
                    return (
                      <DesktopLinkContainer key={navItem.name}>
                        <StyledAniLink cover bg="#000" to={navItem.path}>
                          {navItem.label}
                        </StyledAniLink>
                      </DesktopLinkContainer>
                    );
                  })}
              </AllDesktopLinkContainer>
              {data
                .filter(({ isCTA, isDesktop }) => isCTA && isDesktop)
                .map(navItem => {
                  if (isHomepage) {
                    return (
                      <StyledButton
                        onClick={() => handleNavigation(navItem)}
                        key={navItem.name}
                      >
                        {navItem.label}
                      </StyledButton>
                    );
                  }
                  return (
                    <StyledAniLink cover bg="#000" to={navItem.path}>
                      <StyledButton
                        onClick={() => handleNavigation(navItem)}
                        key={navItem.name}
                      >
                        {navItem.label}
                      </StyledButton>
                    </StyledAniLink>
                  );
                })}
            </>
          )}
        </SideContainer>
      </HeaderContainer>

      {isMobile && isNavigationDrawerOpen && (
        <NavigationDrawer>
          <LinkContainer>
            {data
              .filter(({ isMobile }) => isMobile)
              .map(navItem =>
                isHomepage && navItem.name !== "aboutMe" ? (
                  <NavDrawerLink
                    onClick={() => closeAndNavigate(navItem)}
                    key={navItem.name}
                  >
                    {navItem.label}
                  </NavDrawerLink>
                ) : (
                  <StyledAniLink cover bg="#000" to={navItem.path}>
                    <NavDrawerLink
                      onClick={closeNavigationDrawer}
                      key={navItem.name}
                    >
                      {navItem.label}
                    </NavDrawerLink>
                  </StyledAniLink>
                )
              )}
            {/*  <NavDrawerLink onClick={closeAndNavigate}>My Skills</NavDrawerLink>
            <NavDrawerLink onClick={handleBlogClick}>Blog</NavDrawerLink>
            <NavDrawerLink onClick={handleContactMeClick}>
              Contact Me
            </NavDrawerLink> */}
          </LinkContainer>
          <SocialLinksContainer>
            {socialLinks.map(item => (
              <SocialLink
                href={item.link}
                key={item.name}
                target="_blank"
                ariaLabel={item.name}
              >
                {item.icon}
              </SocialLink>
            ))}
          </SocialLinksContainer>
        </NavigationDrawer>
      )}
    </>
  );
};
export default Header;
