

module.exports = {
  name: "Parmeet Singh Asija",
  usernames: {
    github: "parmeet1402",
    linkedin: "parmeet-asija",
    twitter: "parmeetasija",
    instagram: "parmeetasija",
    facebook: "parmeetasija",
    whatsapp: "+91 7009028471",
    email: "parmeetsasija@gmail.com",
    stackOverflow: "parmeet-singh-asija",
    hashnode: "parmeetasija",
    hackernoon: "parmeetsasija",
    devTo: "parmeetasija",
    medium: "parmeet-asija",
    codepen: "parmeetasija",
    codeSandbox: "parmeet1402",
    reddit: "parmeet-asija",
    quora: "Parmeet Singh",
  },

  links: {
    github: "https://github.com/parmeet1402",
    linkedin: "https://www.linkedin.com/in/parmeet-asija/",
    twitter: "https://twitter.com/parmeetasija",
    instagram: "https://www.instagram.com/parmeetasija/",
    facebook: "https://www.facebook.com/parmeetasija/",
    whatsapp: "http://api.whatsapp.com/send?phone=917009028471",
    email: "mailto:parmeetsasija@gmail.com",
    phone: "tel:+917009028471",
    stackOverflow:
      "https://stackoverflow.com/users/16415678/parmeet-singh-asija",
    hashnode: "https://hashnode.com/@parmeetasija",
    hackernoon: "https://hackernoon.com/u/parmeetsasija",
    devTo: "https://dev.to/parmeetasija",
    medium: "https://medium.com/@parmeet-asija",
    codepen: "https://codepen.io/parmeetasija",
    codeSandbox: "https://codesandbox.io/u/parmeet1402",
    reddit: "https://www.reddit.com/user/parmeet-asija",
    quora: "https://www.quora.com/profile/Parmeet-Singh-585",
  },
};
