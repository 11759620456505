import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
// import vsLight from "prism-react-renderer/themes/vsLight";
import styled from "styled-components";
import { breakpoints } from "../../../theme";

const theme = {
  plain: {
    color: "#36313d",
    backgroundColor: "#FFF9F7",
  },
  styles: [
    {
      types: ["comment"],
      style: {
        color: "#b49383",
        // fontStyle: "italic",
      },
    },
    {
      types: ["builtin"],
      style: {
        color: "rgb(0, 112, 193)",
      },
    },
    {
      types: ["number", "variable", "inserted"],
      style: {
        color: "rgb(9, 134, 88)",
      },
    },
    {
      types: ["operator"],
      style: {
        color: "rgb(0, 0, 0)",
      },
    },
    {
      types: ["constant", "char"],
      style: {
        color: "#b94185",
      },
    },
    {
      types: ["tag"],
      style: {
        color: "#137886",
      },
    },
    {
      types: ["attr-name"],
      style: {
        color: "#b94185",
      },
    },
    {
      types: ["deleted", "string"],
      style: {
        color: "#b94185",
      },
    },
    {
      types: ["changed", "punctuation"],
      style: {
        color: "#53450e",
      },
    },
    {
      types: ["function", "keyword"],
      style: {
        color: "#096fb3",
      },
    },
    {
      types: ["class-name"],
      style: {
        color: "#096fb3",
      },
    },
  ],
};

const Pre = styled.pre`
  overflow-x: auto;
  font-size: 14px;
  padding: 0 24px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 16px;
    padding: 0;
  }

  // borderRadius: "8px",
  // padding: "4px 4px",
  // overflowX: "scroll",
  // overflow: "scroll",
`;

const CodeTitle = styled.div`
  background: #fff9f7;
  width: 100%;

  line-height: 40px;
  border-radius: 4px 4px 0px 0px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    line-height: 55px;
  }
`;
const CodePath = styled.span`
  // margin: 0 auto;
  font-size: 14px;
  padding: 20px 24px 16px;
`;

const CodeWrapper = styled.div`
  background-color: #fff9f7;
  border-top: 1px solid #e3ccc1;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 24px;
  }
`;

const Wrapper = styled.div`
  margin-top: 24px;
`;

const CodeBlock = ({ fileName, children, className }) => {
  // Pull the className
  const language = className.replace(/language-/, "") || "";
  // const className = props.children.props.className || "";

  return (
    <Highlight
      {...defaultProps}
      theme={theme}
      code={children}
      language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Wrapper>
          {
            <CodeTitle>
              <CodePath>{fileName}</CodePath>
            </CodeTitle>
          }
          <CodeWrapper>
            <Pre className={className} style={{ ...style }}>
              {tokens.map((line, index) => {
                const lineProps = getLineProps({ line, key: index });
                return (
                  <div key={index} {...lineProps}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                );
              })}
            </Pre>
          </CodeWrapper>
        </Wrapper>
      )}
    </Highlight>
  );
};

export default CodeBlock;
