import React from "react";

// External
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

// Internal
import { colors, components, fontFamily } from "../../../../theme";

const ButtonStyles = styled.button`
  cursor: pointer;
  user-select: none;

  border: ${props =>
    ["outlined", "solid"].some(variant => props.variant === variant)
      ? `1px solid ${props.bgColor}`
      : "none"};

  background-color: ${props =>
    props.variant === "solid" ? props.bgColor : "transparent"};

  color: ${props =>
    props.variant === "outlined" ? props.bgColor : props.textColor};

  font-size: ${props => components.button.fontSize[props.size]};
  font-family: ${fontFamily.bold};

  padding: ${props => components.button.padding[props.size]};
  border-radius: ${props => components.button.borderRadius[props.size]};

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${props =>
    props.href &&
    css`
      text-decoration: none;
    `}

  &:hover,
  &:focus {
    background-color: ${props => props.bgColor};
    color: ${props => props.textColor};
  }

  &:active {
    background-color: ${props => props.bgColor};
    color: ${props => props.textColor};
  }
`;

const Button = props => {
  return (
    <ButtonStyles as={props.href ? "a" : "button"} {...props}>
      {props.children}
    </ButtonStyles>
  );
};

Button.defaultProps = {
  bgColor: colors.black,
  textColor: colors.white,
  size: "medium",
  variant: "solid",
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "solid", "ghost"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
};

export default Button;
