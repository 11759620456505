// External
import styled from "styled-components";
import PropTypes from "prop-types";

// Internal
import { colors, fontFamily } from "../../../theme";

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-family: ${fontFamily.regular};
  font-weight: normal;
  color: ${props => props.color || colors.black};

  &:hover {
    font-weight: bold;
  }
`;

Link.defaultProps = {
  color: colors.black,
};

Link.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export default Link;
