module.exports = {
  spacing: num => `${num * 4}px`,
  breakpoints: {
    desktop: "1024px",
    tablet: "768px",
    phone: "0px",
  },
fontFamily: {
    bold: `'Plus Jakarta Sans Bold', sans-serif`,
    extraBold: `'Plus Jakarta Sans Extra Bold', sans-serif`,
    medium: `'Plus Jakarta Sans Medium', sans-serif`,
    regular: `'Plus Jakarta Sans', sans-serif`,
  },
  colors: {
    primary: {},
    white: "#fff",
    black: "#000",
    grey: {
      dark: "#0b0d17",
      darkMedium: "#72747e",
      medium: "#d9dbe1",
    },
  },
  typography: {
    h1: {},
    h2: {},
    h3: {},
    h5: {},
    h6: {},
    body: {},
    subtitle: {},
  },
  components: {
    button: {
      padding: {
        large: "",
        medium: "18px 30px",
        small: "12px 16px",
      },
      fontSize: {
        large: "",
        medium: "16px",
        small: "12px",
      },
      borderRadius: {
        large: "",
        medium: "18px",
        small: "18px",
      },
    },
    iconButton: {
      size: { large: "60px", medium: "48px", small: "36px" },
      iconSize: { large: "32px", medium: "24px", small: "16px" },
    },
  },
};
