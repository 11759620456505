import * as React from "react";

import styled from "styled-components";
import { MDXProvider } from "@mdx-js/react";

import Header from "./header";
import Footer from "./footer";
import CodeBlock from "../CodeBlock";
import GlobalStyle from "../../styles/global-styles";
import { breakpoints } from "../../../theme";
import { Toaster } from "react-hot-toast";

/* const codeTypes = {
  color: {
    js: "#f7df1e",
    jsx: "#61dafb",
  },
}; */

//  TODO: Copy Button
// TODO: Code Type Button
// TODO: Header with File Path

const CodeWrapper = styled.div`
  width: 100vw;
  margin-left: -24px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin: 0;
  }
`;

const components = {
  pre: props => <CodeWrapper {...props} />,
  code: CodeBlock,
  // custom components for global access go here...
};

const Main = styled.main`
  margin-top: 80px;
`;

const Layout = ({ children }) => {
  return (
    <MDXProvider components={components}>
      <div>
        <GlobalStyle />
        <Header />
        <Main>{children}</Main>
        <Footer />
        <Toaster position="bottom-left" />
      </div>
    </MDXProvider>
  );
};

export default Layout;
